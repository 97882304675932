import { graphql, useStaticQuery } from 'gatsby';
import * as fundingTransformers from '@content/transformers/FundingPageTransformers';

const FundingQuery = () => {
  const fundingQuery = useStaticQuery(graphql`
    query {
      allSiteSettingEntityFinancing {
        edges {
          node {
            langcode
            field_meta_description
            field_meta_title
            relationships {
              field_main_image {
                localFile {
                  publicURL
                }
              }
              field_components {
                ... on paragraph__branches_hero {
                  id
                  field_branches_button_text
                  field_branches_image {
                    alt
                  }
                  field_branches_image_mobile {
                    alt
                  }
                  field_branches_text {
                    value
                  }
                  field_paragraph_headline {
                    value
                  }
                  relationships {
                    field_branches_image {
                      localFile {
                        childImageSharp {
                          fixed(width: 1600, quality: 90) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    field_branches_image_mobile {
                      localFile {
                        childImageSharp {
                          fixed(width: 800, quality: 90) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__branches_saving_up_block {
                  id
                  field_branches_text {
                    value
                  }
                  field_branches_button_text
                  field_paragraph_headline {
                    value
                  }
                }
                ... on paragraph__bullets_container {
                  id
                  relationships {
                    field_bullets_item {
                      field_text {
                        value
                      }
                      field_video
                      field_image {
                        alt
                      }
                      relationships {
                        field_image {
                          localFile {
                            childImageSharp {
                              fixed(width: 330, quality: 90, fit: CONTAIN) {
                                ...GatsbyImageSharpFixed
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__news_text {
                  id
                  field_nt_text
                }
              }
            }
          }
        }
      }
    }
  `);

  return fundingTransformers.fundingTransformers(fundingQuery.allSiteSettingEntityFinancing);
};

export default FundingQuery;
