import React from 'react';
import FundingContainer from '@containers/funding';
import useFundingQuery from '@graphql/queries/FundingQuery';
import DataLayer from '@components/layout/DataLayer';
import { LocationDependent } from '@content/types/general';
import { PageContext } from '@content/types/pageContext';

interface FundingProps extends LocationDependent {
  pageContext: PageContext;
}

export default (props: FundingProps) => {
  const fundingData = useFundingQuery()[props.pageContext.langcode];

  return (
    <>
      <DataLayer location={props.location} />
      <FundingContainer
        {...fundingData}
        langcode={props.pageContext.langcode}
        urls={props.pageContext.allUrls}
      />
    </>
  );
};
