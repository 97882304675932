import get from 'lodash.get';
import { FileImageSharp } from '@content/types/api';

export const fundingTransformers = page => {
  const fundingLanguages = {};

  page.edges.forEach(edge => {
    const data = edge.node;
    const components = data.relationships;
    const hero =
      components &&
      components.field_components &&
      components.field_components.find(
        component => component.__typename === 'paragraph__branches_hero'
      );
    const bullets =
      components &&
      components.field_components &&
      components.field_components.find(
        component => component.__typename === 'paragraph__bullets_container'
      );
    const saveingUp =
      components &&
      components.field_components &&
      components.field_components.find(
        component => component.__typename === 'paragraph__branches_saving_up_block'
      );
    // const text = components.field_components.find(
    //   component => component.__typename === 'paragraph__news_text'
    // );

    fundingLanguages[data.langcode] = {
      seo: {
        title: get(data, 'field_meta_title', ''),
        description: get(data, 'field_meta_description', ''),
        image: get(data, 'relationships.field_main_image.localFile.publicURL'),
      },
      hero: {
        title: get(hero, 'field_paragraph_headline.value', ''),
        paragraph: get(hero, 'field_branches_text.value', ''),
        button: get(hero, 'field_branches_button_text', ''),
        imageAlt: get(hero, 'field_branches_image.alt', ''),
        image: get(hero, 'relationships.field_branches_image.localFile', null) as FileImageSharp,
        imageMobile: get(
          hero,
          'relationships.field_branches_image_mobile.localFile',
          null
        ) as FileImageSharp,
      },
      bullets: bullets.relationships.field_bullets_item.map((item, index) => ({
        image: get(item, 'relationships.field_image.localFile', null) as FileImageSharp,
        imageAlt: get(item, 'field_image.alt', ''),
        paragraph: get(item, 'field_text.value', ''),
        video: get(item, 'field_video', ''),
        // disabledText: index === 1 ? get(text, 'field_nt_text', '') : '',
        // disabled: index === 1 && get(text, 'field_nt_text', '') !== '' ? true : false,
      })),
      saveingUp: {
        button: get(saveingUp, 'field_branches_button_text', ''),
        paragraph: get(saveingUp, 'field_branches_text.value', ''),
        title: get(saveingUp, 'field_paragraph_headline.value', ''),
      },
    };
  });

  return fundingLanguages;
};
