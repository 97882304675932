import React from 'react';
import { useSpring, useSprings, animated, config } from 'react-spring';
import { Stylable } from '@content/types/general';
import { WaypointDependent } from './AnimationTriggerWaypoint';

interface Props extends Stylable, WaypointDependent {}

const strokeDasharrays = [720];
const initialDelay = 1000;
const stepsDelay = 500;

export default (props: Props) => {
  // lines animation
  const [animLines, animLinesSet] = useSprings(strokeDasharrays.length, i => ({
    from: { strokeDashoffset: strokeDasharrays[i] },
    config: config.default,
  }));
  animLinesSet(i => ({
    strokeDashoffset: 0,
    delay: initialDelay + i * stepsDelay,
  }));

  // dots animation
  const [animDots, animDotsSet] = useSprings(2, i => ({
    from: { opacity: 0 /*, transform: 'scale(0)'*/ },
    config: config.default,
  }));
  animDotsSet(i => ({
    opacity: props.sectionVisible ? 1 : 0,
    // transform: props.sectionVisible ? 'scale(1)' : 'scale(0)',
    delay: initialDelay + i * stepsDelay,
  }));

  // icon animation
  const [animIcon, animIconSet] = useSpring(i => ({
    from: { opacity: 0, transform: 'scale(0) ' },
    config: config.wobbly,
  }));
  animIconSet({
    opacity: props.sectionVisible ? 1 : 0,
    transform: props.sectionVisible ? 'scale(1) ' : 'scale(0)',
    delay: initialDelay + 1000,
  });

  return (
    <div className={props.className}>
      <svg width="600px" height="317px" viewBox="0 0 600 317">
        {/* mask - dashed line */}
        <mask id="mask-1">
          <g stroke="#FFFFFF" strokeLinecap="square" strokeDasharray="3" strokeWidth="1">
            <path d="M4,161.196639 C26.0805993,140.0113 61.0799943,140.479054 82.5865403,162.246918 L217.47169,298.771007 C238.776587,320.334772 273.372144,321.031364 295.527807,300.342692 L549.164527,63.5 L549.164527,63.5"></path>
          </g>
        </mask>

        <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Dofinansowanie">
            <g id="1.-Top">
              <g id="Photo">
                <g id="wallet">
                  {/* line */}
                  <g mask="url(#mask-1)">
                    <animated.path
                      strokeDasharray={strokeDasharrays[0]}
                      strokeDashoffset={animLines[0].strokeDashoffset}
                      d="M4,161.196639 C26.0805993,140.0113 61.0799943,140.479054 82.5865403,162.246918 L217.47169,298.771007 C238.776587,320.334772 273.372144,321.031364 295.527807,300.342692 L549.164527,63.5 L549.164527,63.5"
                      id="Line-3"
                      stroke="#929292"
                      strokeLinecap="square"
                    ></animated.path>
                  </g>

                  {/* dots */}
                  <animated.circle
                    style={animDots[0]}
                    id="Oval"
                    fill="#929292"
                    cx="4.5"
                    cy="161.5"
                    r="4.5"
                  ></animated.circle>
                  <animated.circle
                    style={animDots[1]}
                    id="Oval-Copy"
                    fill="#929292"
                    cx="548.5"
                    cy="64.5"
                    r="4.5"
                  ></animated.circle>

                  {/* icon */}
                  <g transform="translate(529.235751, 0.000000)">
                    <animated.g style={animIcon}>
                      <g stroke="#009944" strokeLinecap="round" strokeLinejoin="round">
                        <path
                          d="M48,32.6177534 C48,35.3363603 48,37.3753154 48,38.7346188 M36.437935,46 C28.8837081,46 17.5523678,46 2.44391408,46 C1.09398181,46 0,44.8953973 0,43.5336438 L0,10.4663562 C0,9.10356164 1.09398181,8 2.44391408,8 L45.5560859,8 C46.9060182,8 48,9.10356164 48,10.4663562 L48,21.3853699"
                          id="Shape"
                        ></path>
                        <path
                          d="M51.5037513,33 L40.4973205,33 C39.6698821,33 39,32.3615798 39,31.5752828 L39,23.4236974 C39,22.6374003 39.6698821,22 40.4973205,22 L51.5037513,22 C52.3301179,22 53,22.6374003 53,23.4236974 L53,31.5752828 C53,32.3615798 52.3301179,33 51.5037513,33 Z"
                          id="Stroke-3"
                        ></path>
                        <polyline id="Stroke-5" points="14 8 29.2088707 0 32 5.07279497"></polyline>
                        <polyline id="Stroke-7" points="27 8 36.3067253 3 39 8"></polyline>
                        <g id="Group-9" transform="translate(41.000000, 42.000000)">
                          <path
                            d="M0,4 C0,1.79068267 4.70092947,0 10.5002734,0 C16.2996173,0 21,1.79068267 21,4"
                            id="Stroke-1"
                          ></path>
                          <path
                            d="M21,4 C21,6.20917479 16.2990705,7.99974192 10.4997266,7.99974192 C4.70092947,7.99974192 0,6.20917479 0,4 L0,8.00025808 C0,10.2094329 4.70092947,12 10.4997266,12 C16.2990705,12 21,10.2094329 21,8.00025808 L21,4 Z"
                            id="Stroke-3"
                          ></path>
                          <path
                            d="M21,12 L21,13.0903128 C21,15.2492432 16.2990705,17 10.4997266,17 C4.70092947,17 0,15.2492432 0,13.0903128 L0,12"
                            id="Stroke-5"
                          ></path>
                          <path
                            d="M21,16 L21,17.5622793 C21,20.0133626 16.2990705,22 10.4997266,22 C4.70092947,22 0,20.0133626 0,17.5622793 L0,16"
                            id="Stroke-7"
                          ></path>
                        </g>
                      </g>
                    </animated.g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
