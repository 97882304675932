import React from 'react';
import { FundingPageBullets } from '@content/types/funding';
import BranchBullets from '@components/branches/sections/BranchBullets';

interface FundingSectionsProps {
  data: FundingPageBullets[];
  langcode: string;
  urls: Array<string>;
}

const FundingSections = (props: FundingSectionsProps) => (
  <BranchBullets
    fundingPage={true}
    circles={[
      { position: 0, rotate: -140, size: 'big', side: 'right' },
      { position: 2, rotate: -55, size: 'big', side: 'left' },
    ]}
    bullets={props.data}
    langcode={props.langcode}
    urls={props.urls}
  />
);

export default FundingSections;
