import React from 'react';
import styled from '@emotion/styled-base';

import { respondFrom, breakpoints, css } from '@styles';
import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import Hero from '@components/common/Hero';
import FundingSections from '@components/funding/FundingSections';
import BannerSavings from '@components/common/BannerSavings';
import MetaPage from '@components/common/MetaPage';
import * as utils from '@utils';

import AnimationDofinansowanieHero from '../../animations/AnimationDofinansowanieHero';

const StyledAnimationDofinansowanieHero = styled(AnimationDofinansowanieHero)`
  display: none;

  ${respondFrom(
    breakpoints.md,
    css`
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(9%, 40%);
    `
  )}
`;

export interface FundingProps {
  langcode: string;
  urls: Array<string>;
}

export default props => {
  return (
    <Page>
      <MetaPage title={props.seo.title} description={props.seo.description} image={props.seo.image} />
      <Hero
        size="medium"
        image={props.hero.image.childImageSharp}
        imageMobile={props.hero.imageMobile.childImageSharp}
        imageAlt={props.hero.imageAlt}
        title={props.hero.title}
        paragraph={utils.SafeHtml(props.hero.paragraph)}
        buttonText={props.hero.button}
        buttonRoute={utils.langLink(props.langcode, props.urls[4])}
        theme="dark"
        additionalContent={<StyledAnimationDofinansowanieHero sectionVisible={true} />}
      ></Hero>

      <Container>
        <FundingSections data={props.bullets} langcode={props.langcode} urls={props.urls} />
      </Container>

      <BannerSavings
        title={props.saveingUp.title}
        text={props.saveingUp.paragraph}
        buttonText={props.saveingUp.button}
        langcode={props.langcode}
        urls={props.urls}
      />
    </Page>
  );
};
