import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import actions from '@store/actions';
import FundingComponent, { FundingProps } from '@components/funding';
import { RootState } from '@store/types';

const mapStateToProps = (state: RootState) => ({
  configurator: state.configurator,
  content: state.content,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  configuratorActions: bindActionCreators(actions.configurator, dispatch),
  contentActions: bindActionCreators(actions.content, dispatch),
});

interface DFundingProps extends FundingProps {}
const DFunding: React.FC<DFundingProps> = (props: DFundingProps) => <FundingComponent {...props} />;

const FundingConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DFunding);

export default FundingConnected;
